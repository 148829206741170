<template>
    <v-app>
        <v-main>
            <FormScan :accion="'Digitalizar'" />
        </v-main>
    </v-app>
</template>
<script>
import FormScan from '../components/FormScan'
export default {
    components: {
        FormScan
    },
    data(){
        return{

        }
    },
}
</script>