<template>
  <v-app>
    <v-main>
      <v-container class="mt-5">
        <v-row align="center" justify="center" no-gutters wrap>
          <v-col cols="12" :sm="accion == 'Iniciar caso' ? '6' : '12'">
            <v-autocomplete
              prepend-icon="mdi-file-document"
              @change="seleccionTipoDocumental"
              :items="tipos_documentales"
              label="Selecciona el tipo documental"
              v-model="selectDocumento"
              rounded
              filled
              dense
            />
          </v-col>
          <v-col v-if="accion == 'Iniciar caso'" cols="12" sm="6">
            <v-text-field
              v-model="nombreDocumento"
              prepend-icon="mdi-file-document"
              label="Nombre o identificacion del ciclo"
              required
              rounded
              filled
              dense
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="accion == 'Iniciar caso'">
            <v-text-field
              v-model="correo_de_contacto"
              prepend-icon="mdi-email"
              label="Correo electronico de contacto de la persona"
              required
              rounded
              filled
              dense
            />
          </v-col>
          <v-col v-if="accion != 'digitalizar desde un caso'" cols="12" sm="6">
            <v-select
              rounded
              filled
              dense
              prepend-icon=" mdi-filter"
              v-model="vSelect"
              label="Selecciona un modo de subir archivos"
              :items="
                accion == 'Iniciar caso'
                  ? [
                      'Scanner',
                      'Seleccionar Archivo',
                      'No se subiran archivos al ciclo',
                    ]
                  : ['Scanner', 'Seleccionar Archivo']
              "
            />
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col v-if="accion == 'Iniciar caso'">
            <div
              v-if="
                nombreDocumento != '' &&
                  selectDocumento != '' &&
                  vSelect != '' &&
                  correo_de_contacto != ''
              "
              v-text="
                'Llenar el formulario con los datos requeridos por el tipo de documento seleccionado '
              "
              style="text-align: center"
              class="headline font-weight-light"
            />
          </v-col>
          <v-col v-else-if="selectDocumento != '' && vSelect != ''">
            <div
              v-text="
                'Llenar el formulario con los datos requeridos por el tipo de documento seleccionado'
              "
              style="text-align: center"
              class="headline font-weight-light"
            />
          </v-col>
        </v-row>

        <v-container v-if="accion == 'Iniciar caso'">
          <v-row
            v-if="
              nombreDocumento != '' &&
                selectDocumento != '' &&
                vSelect != '' &&
                correo_de_contacto != ''
            "
          >
            <v-col>
              <v-form ref="form" v-model="valid" lazy-validation>
                <InputsTipoDocumental />
                <v-row v-if="accion == 'Iniciar caso'">
                  <v-col>
                    <v-row>
                      <v-checkbox
                        label="Seleccione esta casilla si el ciclo de trabajo será fijo"
                        color="red darken-4"
                        v-model="ciclo_fijo"
                      >
                      </v-checkbox>
                    </v-row>
                    <v-row v-if="!ciclo_fijo">
                      <WorkFlowDinamico :workflow="[]" />
                    </v-row>
                    <v-row v-else-if="ciclo_fijo == true">
                      <v-col>
                        <v-select
                          v-model="workflow_seleccionado"
                          :items="workflows"
                          item-text="nombre"
                          return-object
                          prepend-icon="mdi-chart-timeline-variant"
                          label="Selecciona un flujo de trabajo"
                          rounded
                          filled
                          dense
                          @change="dibujar_workflow()"
                        >
                        </v-select>
                        <v-row
                          v-if="mostrar_workflow"
                          align="center"
                          justify="center"
                        >
                          <v-col cols="11">
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="title font-weight-light"
                                >
                                  Workflow Seleccionado:
                                  {{
                                    workflow_seleccionado.nombre
                                  }}</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                  <v-sheet
                                    elevation="2"
                                    color="grey lighten-2"
                                    rounded=""
                                  >
                                    <v-row>
                                      <v-col>
                                        <v-container>
                                          <FlowyPlugin
                                            :nodes="nodes_workflow"
                                          />
                                        </v-container>
                                      </v-col>
                                    </v-row>
                                  </v-sheet>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <drop
                  :permitir="'nop'"
                  v-show="vSelect == 'Seleccionar Archivo'"
                  v-on:info="pruebaDrop"
                />
                <v-row>
                  <v-col>
                    <v-btn
                      color="blue darken-3"
                      dark
                      rounded
                      elevation="5"
                      class="mt-4"
                      block
                      @click="getImages"
                      v-if="vSelect == 'Scanner'"
                    >
                      SCANNEAR
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="vSelect == 'No se subiran archivos al ciclo'">
                  <v-btn
                    @click="terminarProcesoUsuario"
                    block
                    rounded
                    dark
                    color="blue darken-3"
                  >
                    Iniciar ciclo
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-row v-else justify="center" align="center">
            <v-col>
              <div
                v-text="'Rellenar Campos'"
                style="text-align: center"
                class="headline font-weight-light"
              />
              <v-img
                src="../assets/rellenarCampos.png"
                contain
                max-height="400"
                max-width="400"
                class="mx-auto"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-container v-else-if="accion == 'Digitalizar'">
          <v-row v-if="selectDocumento != '' && vSelect != ''">
            <v-col>
              <v-form ref="form" v-model="valid" lazy-validation>
                <InputsTipoDocumental />

                <drop
                  :permitir="'nop'"
                  v-if="vSelect == 'Seleccionar Archivo'"
                  v-on:info="pruebaDrop"
                />
                <v-row>
                  <Carousel
                    @move_image="set_index_file_to_move($event)"
                    @eliminar="eliminar_archivo($event)"
                    :imagenes.sync="imagenes"
                    @save_image_edited="save_image_edited($event)"
                    @upload_images="upload_images($event)"
                  />
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      color="blue darken-3"
                      large
                      dark
                      rounded
                      elevation="5"
                      class="mt-4"
                      block
                      @click="getImages"
                      v-if="vSelect == 'Scanner'"
                    >
                      SCANNEAR
                    </v-btn>
                  </v-col>
                  <v-col v-if="imagenes.length > 0">
                    <v-btn
                      color="blue darken-3"
                      large
                      dark
                      rounded
                      elevation="5"
                      class="mt-4"
                      block
                      @click="terminarProceso()"
                    >
                      Finalizar
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-divider vertical></v-divider>
          </v-row>
          <!-- Imagen de "Rellenar Campos" -->
          <v-row v-else justify="center" align="center">
            <v-col>
              <div
                v-text="'Rellenar Campos'"
                style="text-align: center"
                class="headline font-weight-light"
              />
              <v-img
                src="../assets/rellenarCampos.png"
                contain
                max-height="400"
                max-width="400"
                class="mx-auto"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>
    <v-dialog v-model="dialogo_ciclo" max-width="600" persistent>
      <v-card>
        <v-card-text>
          <br />
          <v-divider></v-divider>
          <h3 style="text-align:center;color:green">
            EXITOSO
          </h3>
          <v-divider></v-divider>
          <br />
          <h3>
            Los datos para iniciar el ciclo han sido creados exitosamente. El
            token del ciclo es: {{ $store.state.token }}
          </h3>

          <v-img
            :src="urlQr"
            class="mx-auto my-5"
            :aspect-ratio="16 / 9"
            contain
            max-width="450"
          >
          </v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn block @click="dialogo_ciclo = false" color="green" dark>
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_move_file" max-width="400">
      <v-card>
        <v-card-title>
          Moveras la imagen en la posición: {{ index_to_move.old + 1 }}
        </v-card-title>
        <v-card-title> A la posición: {{ index_to_move.new }} </v-card-title>
        <v-card-text>
          <v-text-field
            label="Indica la posición del traslado"
            v-model="index_to_move.new"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn dark @click="move_file()" block color="indigo darken-4">
            Mover imagen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import InputsTipoDocumental from "./formularios/InputsTipoDocumental";
// import Load from "./Load";
import Drop from "../components/Drop";
import Carousel from "./celulas/Carousel.vue";
import FlowyPlugin from "./admin/flowy-vue";
import WorkFlowDinamico from "../components/comunes/WorkFlowDinamico.vue";
import { mapState } from "vuex";
const axios = require("axios").default;

export default {
  components: {
    Drop,
    InputsTipoDocumental,
    FlowyPlugin,
    WorkFlowDinamico,
    Carousel,
  },
  props: {
    accion: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    vSelect: "",

    ciclo_fijo: false,
    //---------------------------------------------------------------------------------
    //Variables agregadas por fry que contienen los tipos documentales de la plataforma
    tipos_documentales: [],
    tipos_documentales_completo: [],
    nombre_endpoint_almacenamiento: "",
    datos_archivos: [],

    urlQr: "",
    dialogo_ciclo: false,

    //Variables que usa el recepcionista agregadas por fry
    correo_de_contacto: "",
    workflows: [],

    workflow_seleccionado: "",
    mostrar_workflow: false,
    nodes_workflow: [],
    //------------------------------------

    contadorImagensSubidas: 0,
    subiendoImagenes: false,

    nombreDocumento: "",
    load: false,

    now: null,
    nowMenu: false,

    urlscan: require("../assets/img.png"),
    imagenes: [],
    scan: null,

    img: "",
    menu: false,

    scannerInicio: false,
    valid: true,

    select: [],

    items: [],
    itemsCompleto: [],

    selectDocumento: "",

    result: [],
    propiedades: null,
    carpeta: [],
    files: [],

    ws: null,
    indice: 1,

    index_to_move: {
      old: 0,
      new: 0,
    },
    dialog_move_file: false,

    position_to_insert: -1,
    //files_format_blob: [],
    imagenes_pdf: 1,
  }),
  async created() {
    let _this = this;
    const that = this;
    console.log("Holsi");
    window.URL = window.URL || window.webkitURL;
    var wsImpl = window.WebSocket || window.MozWebSocket;

    this.ws = new wsImpl("ws://localhost:8181/");

    this.ws.onmessage = function(e) {
      console.log(e);
      try {
        if (e.data instanceof Blob) {
          //-----Estas son las funciones que debe ejecutar tu input----//
          that.seleccionarFoto(e.data);

          that.addFiles(e.data, that.$store.state.token, that.indice, "blob");
          //---------------------------------------------------------//
        }
      } catch (error) {
        console.log(error);
      }
    };

    this.ws.onopen = function() {
      console.log("Open ");
      console.log(that.indice);
      //btn_scan.removeAttribute('disabled');
      //download_app.style.display = 'none';
    };
    this.ws.onerror = function(e) {
      console.log(e);
      console.log("Error");
      _this.$store.commit(
        "error",
        "El programa SCAN APP no ha sido activado. Por favor inicielo en el escritorio e ingrese nuevamente a esta vista si desea ESCANEAR archivos, si ese no es el caso ignore este mensaje"
      );
      //btn_scan.setAttribute('disabled', '');
      //download_app.style.display = '';
    };

    this.$store.commit("crearToken", 25);
    this.$store.commit("auth", "parse");

    console.log(this.$store.state.token);
    //this.pruebaFinal()

    if (this.accion == "Iniciar caso") {
      //Condicional cambiado por fry 13/02/2021
      //Indicamos si el componente padre es archivador o usuario (usuario es el usuario supervisor del sector anteriormente llamado radicador )
      //Para saber si debemos ejecutar esta funcion que sirve para
      //this.consultarEspecialista();
      this.consultar_workflow();
    }
    this.consultar_tipos_documentales(); //Funcion agregada por fry 13/02/2021
  },
  methods: {
    prueba_rotate(foto) {
      console.log(foto);
      this.seleccionarFoto(foto);
    },
    eliminar_archivo(indice) {
      this.imagenes.splice(indice, 1);
      this.datos_archivos.splice(indice, 1);
      this.files.splice(indice, 1);
      //this.files_format_blob.splice(indice,1)
    },
    save_image_edited(file) {
      try {
        console.log(file);
        let file_formdata = new FormData();
        file_formdata.append("file", file.image);
        file_formdata.append("texto", this.datos_archivos[file.index].token);
        file_formdata.append("nombre", this.datos_archivos[file.index].nombre);
        this.files[file.index] = file_formdata;
        var reader = new FileReader();
        reader.onload = async (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData

          var result = e.target.result;
          let imagenes = this.insert_element_at_postition(
            this.imagenes,
            file.index,
            result
          );
          imagenes.splice(file.index + 1, 1);
          console.log(imagenes);
          this.imagenes = [...imagenes];
        };

        reader.readAsDataURL(file.image);

        //this.imagenes[file.index] = file.image;
      } catch (error) {
        console.log(error);
      }
    },
    upload_images(index_initial) {
      try {
        console.log("Por qué se llama por aca esto");
        console.log(index_initial);

        this.position_initial = index_initial;
        this.getImages();
      } catch (error) {
        console.log(error);
      }
    },
    set_index_file_to_move(index) {
      console.log(index);
      this.index_to_move.old = index;

      this.dialog_move_file = true;
    },
    insert_element_at_postition(old_array, index, element) {
      try {
        console.log(old_array);
        old_array.splice(index, 0, element);
        return old_array;
      } catch (error) {
        console.log(error);
      }
    },
    async move_file() {
      try {
        const index = this.index_to_move.new - 1;
        const new_array = {
          imagenes: await this.insert_element_at_postition(
            this.imagenes,
            index,
            this.imagenes[this.index_to_move.old]
          ),
          datos_archivos: await this.insert_element_at_postition(
            this.datos_archivos,
            index,
            this.datos_archivos[this.index_to_move.old]
          ),
          files: await this.insert_element_at_postition(
            this.files,
            index,
            this.files[this.index_to_move.old]
          ),
        };

        console.log(new_array);
        this.eliminar_archivo(this.index_to_move.old);
        this.dialog_move_file = false;
        this.$store.commit(
          "exitoso",
          "La imagen ha sivo movida de forma exitosa"
        );
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Algo ha salido mal moviendo la imagen " + error.message
        );
      }
    },
    dibujar_workflow() {
      console.log(this.workflow_seleccionado);
      /*
      let aux = this.workflows_completo.filter(
        (item) =>
          item.nombre
            .toLowerCase()
            .indexOf(this.workflow_seleccionado.toLowerCase()) !== -1
      );
       console.log(aux);
      */

      this.nodes_workflow = this.workflow_seleccionado.workflow;
      this.mostrar_workflow = true;
    },
    async consultar_workflow() {
      this.$store.commit("auth", "parse");

      try {
        let response = await axios.get(
          "https://audidoc.educandote.co/parse/classes/prueba01workflows",
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );

        this.workflows = [...response.data.results];
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    async consultar_tipos_documentales() {
      let response = await this.$store.dispatch("llamado_get", {
        url:
          "https://audidoc.educandote.co/parse/classes/prueba01Sector/" +
          this.$store.state.dataUser.dataUser.sector,
        tipo_header: "parse",
      });

      for (
        let index = 0;
        index < response.data.tipos_documentales.length;
        index++
      ) {
        this.tipos_documentales.push(
          response.data.tipos_documentales[index].nombre
        );
      }
      this.tipos_documentales_completo = response.data.tipos_documentales;
    },

    async pruebaDrop(event) {
      console.log("Funcion prueba Drop");
      console.log(event);
      console.log(event.archivo);
      this.scannerInicio = true;

      for (let index = 0; index < event.archivo.length; index++) {
        await this.addFiles(
          event.archivo[index],
          this.$store.state.token,
          event.obj[index].nombre,
          event.obj[index].tipo
        );
      }

      /* Eduard V*/
      var reader = new FileReader();
      reader.readAsBinaryString(event.archivo[0]);
      reader.onloadend = () => {
        this.imagenes_pdf = reader.result.match(
          /\/Type[\s*]*\/Page[^s]/g
        ).length;
        console.log("Number of Pages:", this.imagenes_pdf);
      };

      this.terminarProceso();
      //Comentarios
      // this.archivo2 = event.archivo
      // this.objUpload2 = event.obj
    },
    seleccionarFoto(event) {
      console.log(event);
      //event.setAttribute("style", "transform: rotate(" + 90 + "deg)");
      var reader = new FileReader();

      reader.onload = async (e) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData

        var result = e.target.result;

        if (this.position_initial == -1) {
          this.imagenes.push(result);
        } else {
          const images = await this.insert_element_at_postition(
            this.imagenes,
            this.position_initial,
            result
          );
          this.imagenes = [...images];
        }
      };

      reader.readAsDataURL(event);
    },

    async concatenarDatos() {
      let obj = "";
      for (
        let index = 0;
        index <
        this.$store.state.formularios.inputs_tipo_documental.campos.length;
        index++
      ) {
        obj =
          obj +
          `"${this.$store.state.formularios.inputs_tipo_documental.campos[index].key}":"${this.$store.state.formularios.inputs_tipo_documental.campos[index].value}",`;

        if (
          this.$store.state.formularios.inputs_tipo_documental.campos[index]
            .type == "date"
        ) {
          let date_to_set = 0;
          if (
            this.$store.state.formularios.inputs_tipo_documental.campos[index]
              .value != ""
          ) {
            date_to_set = Date.parse(
              new Date(
                this.$store.state.formularios.inputs_tipo_documental.campos[
                  index
                ].value
              )
            );
          }

          let field = `${this.$store.state.formularios.inputs_tipo_documental.campos[index].key}_int`.toLocaleLowerCase();

          obj = obj + `"${field}":${date_to_set},`;
        }
      }
      let objeto = obj.substring(0, obj.length - 1);
      //obj = obj +`"fecha_limite":"${this.$store.state.formularios.inputs_tipo_documental.fecha_limite}"`;
      console.log(objeto);
      obj = "{" + objeto + "}";
      console.log(this.$store.state.formularios.inputs_tipo_documental);

      return obj;
    },

    async addFiles(blob, token, index, tipo) {
      //Se agrego un cuarto parametro llamado tipo el cual contendra
      //El tipo de archivo que se esta agregando .zip png jpg blob etc etc etc.
      //Con el fin de agregarlo al endpoint de almacenamiento y saber como debe hacerse la estructura de las url
      //Que permiten descargar los archivos la cual seria: http://url-endpoint/token/nombre.tipo
      let file = new FormData();
      console.log(token);
      file.append("file", blob);
      file.append("texto", token);
      file.append("nombre", index);

      console.log(blob);

      let obj = {
        nombre: index,
        token: token,
        tipo: tipo,
        autor: this.$store.state.dataUser.dataUser.nombre,
        sector: this.$store.state.dataUser.dataUser.sector,
        nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
        fecha_adjuntado: Date.parse(new Date()), // Indicara la fecha cuando se adjunto la imagen
        meta_datos: [],
        estado: "habilitada",
      };

      obj = JSON.stringify(obj);
      if (this.position_to_insert == -1) {
        this.datos_archivos.push(obj);
        this.files.push(file);
        //this.files_format_blob.push(blob);
      } else {
        const datos_archivos = await this.insert_element_at_postition(
          this.datos_archivos,
          this.position_to_insert,
          obj
        );
        const files = await this.insert_element_at_postition(
          this.files,
          this.position_to_insert,
          obj
        );
        //const files_format_blob = await this.insert_element_at_postition(this.files_format_blob,this.position_to_insert,obj);
        this.datos_archivos = [...datos_archivos];
        this.files = [...files];
        //this.files_format_blob = [...files_format_blob];

        console.log(datos_archivos);
        console.log(files);
      }

      this.indice++;
    },
    async uploadImg() {
      //var token = 'TokenPrueba2'
      this.$store.state.load = true;

      //Activar un spinner load

      for (let index = 0; index < this.files.length; index++) {
        try {
          this.contadorImagensSubidas++;
          this.$store.commit("auth");

          if (index + 1 == 1) {
            //Upload docu crea la carpeta y anexa la primera imagen
            var responseUploadImg = await axios.post(
              "https://drive.educandote.co/audidoc/uploadDocu.php",
              this.files[index],
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );
            console.log("Upload Imagen 1", responseUploadImg);
            if (responseUploadImg.data.message == "Success") {
              console.log("Se subio el archivo.");
            } else {
              console.log("No se subio el archivo.");
              return false;
            }
          } else {
            //upgradeFolder.php anexa, datos al folder asociado al token

            var response = await axios.post(
              "https://drive.educandote.co/audidoc/upgradeFolder.php",
              this.files[index],
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );

            console.log("Upload Imagen " + index);
            console.log(response);
            if (response.data.message == "Success") {
              console.log("Se subio a la Carpeta.");
            } else {
              console.log("No se subio a la Carpeta.");
              return false;
            }
          }
        } catch (error) {
          //Posteriormente indicar cual sera el error en un dialog
          console.log(error);
          this.$store.state.load = false;
          return false; //Se retorna falso cuando existe un error en la subida de alguna imagen
        }
      }

      return true;
    },
    async getImages() {
      this.ws.send("1100");
      this.scannerInicio = true;
    },

    terminarProceso() {
      this.contadorImagensSubidas = 0;
      this.subiendoImagenes = true;
      if (this.accion == "Iniciar caso") {
        this.terminarProcesoUsuario();
      } else {
        this.terminarProcesoArchivador();
      }
      this.workflow_seleccionado = "";
    },
    async terminarProcesoUsuario() {
      if (this.correo_de_contacto != "") {
        if (this.vSelect != "No se subiran archivos al ciclo") {
          if (this.imagenes.length > 0 || this.datos_archivos.length > 0) {
            var subidaImagenes = await this.uploadImg();
            if (subidaImagenes) {
              console.log(this.imagenes.length);
              await this.enviar_ciclo();
              this.subiendoImagenes = false;
            } else {
              this.$store.commit(
                "error",
                "Ha ocurrido un error inesperado en el momento de crear la coleccion para almacenar los datos"
              );
              console.log("Ha ocurrido un error subiendo alguna imagen");
              this.subiendoImagenes = false;
            }
          } else {
            this.$store.commit(
              "error",
              "Por favor escanea algunas imagenes antes de continuar en el procedimiento"
            );
            this.subiendoImagenes = false;
          }
        } else {
          this.$store.state.load = true;
          await this.enviar_ciclo();
          this.subiendoImagenes = false;
          this.selectDocumento = "";
        }
      } else {
        this.$store.commit(
          "error",
          "Por favor coloca un correo electronico al cual se le enviaran los datos de respuesta del caso a la persona que los ha solicitado"
        );
        this.subiendoImagenes = false;
      }
    },
    async enviar_ciclo() {
      try {
        //Enviar datos al endpoint que contendra todos los casos
        //Y enviar el caso al o los supervisores que esten justo por debajo del punto inicial
        //Para que estos posteriormente lo remitan a sus empleados y empezar con el flujo
        var f = new Date();
        var fecha =
          f.getFullYear() +
          "-" +
          ("0" + (f.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + f.getDate()).slice(-2);
        console.log(fecha); //Esta fecha hay que tomarla con referencia al servidor o al createdAt es mejor //Esto debe hacerse en el perfil de supervisor a la hora de delegar usuarios para responder el caso
        this.$store.commit("crearToken", 25);
        this.$store.commit("auth", "parse");
        let workflow = [],
          sectores;
        if (!this.ciclo_fijo) {
          workflow = await this.$store.dispatch(
            "workflows/agregar_nodos_workflow",
            {
              areas: this.$store.state.workflows.areas_seleccionadas,
              parentId: 1,
            }
          );
        } else {
          workflow = [...this.nodes_workflow];
        }

        //La funcion le pasamos el workflow y el id del nodo al cual queremos encontrarle los sectores
        //a los cuales le enviaremos el ciclo, como este es el inicial le pasamos el 1
        sectores = await this.$store.dispatch("workflows/buscar_sectores", {
          workflow: workflow,
          id: 1,
        });

        let response = await axios.post(
          "https://audidoc.educandote.co/parse/classes/prueba01TodosLosCasos",
          {
            token: this.$store.state.token,
            nombre: this.nombreDocumento,
            remitente: this.$store.state.dataUser.dataUser.nombre,
            tipo_documental: this.selectDocumento,
            workflow: workflow,
            fecha_asignacion_caso: fecha,
            fecha_int: Date.parse(new Date()),
            //fecha_limite_caso: this.$store.state.formularios.inputs_tipo_documental.fecha_limite,
            correo_de_contacto: this.correo_de_contacto,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );

        console.log(response);

        let obj = {};
        let datos = await this.concatenarDatos();
        let meta_data = [];
        meta_data.push(datos);
        console.log(meta_data);
        //---Estos campos serviran para saber a quien se le debe notificar una respuesta de aceptado o rechazado
        obj.id_nodo_sector_anterior_numeric = 1; // 01-03 Motivo de reasignacion
        obj.remitente = this.$store.state.dataUser.dataUser.nombre;
        obj.identificacion_remitente = this.$store.state.dataUser.dataUser.identificacion;
        obj.correo_remitente = this.$store.state.dataUser.dataUser.correo;
        obj.area_remitente = this.$store.state.dataUser.dataUser.sector;
        obj.userId_remitente = this.$store.state.dataUser.dataUser.userId;
        obj.inicio = true;
        //-----------------------------------------------------
        //Estos objetos de fechas son para generar los reportes-------
        (obj.fecha_aceptado = 0), //Cambiara de null a la fecha si el caso es aceptado por un supervisor
          (obj.fecha_rechazado = 0), //Cambiara de null a la fecha si el caso es rechazado por un supervisor
          (obj.fecha_respondido = 0), //Cambiara de null a la fecha cuando el caso es respondido por un supervisor
          //-------------------------------------------------------------

          (obj.tipo_documental = this.selectDocumento);
        obj.nombre_documento = this.nombreDocumento; //Nombre del documento con el cual se recibira en la bandeja de entrada
        obj.meta_data = JSON.parse(meta_data); //Son los inputs del tipo documental seleccionado
        obj.token = this.$store.state.token; //Token del caso
        obj.estado = "Pendiente"; // Este estado indicara "si esta en progreso, o ya fue respondido, rechazado, aceptado"
        obj.nombre_supervisor = null; //Campo que se llenara una vez algun supervisor haya aceptado el caso
        obj.identificacion_supervisor = null; //se llenera cuando algun supervisor acepte
        obj.correo_supervisor = null; //Se llenara una vez algun supervisor acepte
        obj.responsables = []; // los responsables del area al que ingresa sera un array de objetos de la forma: {nombre: 'fry', identificacion: 1004 } este array se llenara en la vista del supervisor

        obj.workflow = workflow; //workflow asignado por el recepcionista que indica las areas por las que pasa el documento

        obj.fecha_asignacion_caso = fecha; //Punto inicial el dia que el recepcionista coloco el caso
        (obj.fecha_asignacion_int = Date.parse(new Date(fecha))), //Para calcular intervalos en los reportes
          (obj.fecha_salida = null); // fecha en la que se culmina su proceso dentro del area y es enviado a otra area o al usuario final
        obj.fecha_salida_int = 0;
        //obj.fecha_limite_caso = this.$store.state.formularios.inputs_tipo_documental.fecha_limite; //Limite legal del documento establecido en el tipo documental

        obj.correo_de_contacto = this.correo_de_contacto; //Correo al que se le respondera el caso asignado
        obj.informacion_area = []; //Informacion proporcionado por los empleados o por el mismo en dado caso decida responderlo el mismo supervisor
        obj.carpeta_publica = this.datos_archivos;
        obj.comentario = "Nuevo ciclo de trabajo";
        obj.ciclo_fijo = this.ciclo_fijo;

        //Un array de objetos que contendra desde la informacion del recepcionista ademas de las respuestas
        //dadas por cada area, los comentarios etc etc. Cada vez que se haga una respuesta de un area la informacion contenidoa en
        //Informacion area pasara a la siguiente area como informacion adicional a la carpeta publica

        //Esto de aqui debe ir en un ciclo que ejecute tantas veces como bifurcaciones tenga por debajo
        //del punto de inicio el workflow

        console.log(sectores);

        console.log(obj);
        for (let index = 0; index < sectores.length; index++) {
          obj.area_id = sectores[index].area_id;
          obj.sector = sectores[index].nombre_sector;
          obj.id_nodo_sector = sectores[index].id_nodo_sector; //Este resulta
          //De identificar cual es el ID del nodo al que se le envia el caso
          //Puesto que se puede dar el caso que una misma area remita a multiples oficinas
          //Y a la hora de dar respuesta se necesita una forma de identificar en que NODO se encuentra
          //Con un valor unico porque el nombre no nos vale.
          console.log(obj);
          this.$store.commit("auth", "parse");
          let response3 = await axios.post(
            "https://audidoc.educandote.co/parse/classes/prueba01casos",
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );
          console.log(response3);
        }

        this.imagenes = [];
        this.files = [];
        this.indice = 0;
        this.contadorImagensSubidas = 0;
        this.scannerInicio = false;

        this.reset();
        this.correo_de_contacto = "";

        this.urlQr =
          "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://audidoc.educandote.co/info_usuarios/" +
          this.$store.state.token;
        this.dialogo_ciclo = true;
        this.$store.state.load = false;
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado en el momento de almacenar los datos en la coleccion " +
            error.message
        );
        console.log("error 2");
        this.subiendoImagenes = false;
      }
      this.$store.state.workflows.workflow = [];
      this.$store.state.workflows.areas_seleccionadas = [];

      //this.$store.state.formularios.inputs_tipo_documental.campos = []
    },
    async set_pdf(body, collections) {
      try {
        const pdf = await this.$store.dispatch("llamado_post", {
          url: `https://docutest.educandote.co/types/collections/IMAGENES_${collections}/documents/import?action=create`,
          body: body,
          tipo_header: "indexador",
        });

        console.log(pdf);
      } catch (error) {
        console.log(error);
      }
    },
    async set_images(body, collections) {
      try {
        const imagenes = await this.$store.dispatch("llamado_post", {
          url: `https://docutest.educandote.co/types/collections/IMAGENES_${collections}/documents/import?action=create`,
          body: body,
          tipo_header: "indexador",
        });
        console.log(imagenes);
      } catch (error) {
        console.log(error);
      }
    },
    async get_ids_folder(token, collections) {
      let result = {
        id_images: [],
        id_pdf: "",
      };

      try {
        console.log(token);
        const response = await this.$store.dispatch("llamado_get", {
          url: `https://docutest.educandote.co/types/collections/IMAGENES_${collections}/documents/search?q=${token}&query_by=${"token"}&filter_by=token:[${token}]&per_page=${250}&sort_by=order:asc`,
          tipo_header: "indexador",
        });

        for (let index = 0; index < response.data.hits.length; index++) {
          if (response.data.hits[index].document.tipo == "pdf") {
            result.id_pdf = response.data.hits[index].document.id;
          } else {
            result.id_images.push(response.data.hits[index].document.id);
          }
        }
        console.log(result);

        //console.log(ids);
      } catch (error) {
        console.log(error);
      }
      return result;
    },
    async contarPaginasPDF(folder, archivo) {
      var formData = new FormData();
      formData.append("texto", folder);
      formData.append("nombre", archivo);
      const config = {
        url: "https://drive.educandote.co/audidoc/countPDF.php",
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const { data } = await axios(config);

        return data.message == "" ? "0" : data.message;
      } catch (error) {
        // return error;
        return "0";
      }
    },
    async terminarProcesoArchivador() {
      if (this.imagenes.length > 0 || this.datos_archivos.length > 0) {
        this.$store.state.dialogCargando = true;
        var subidaImagenes = await this.uploadImg(); //Este upload permite subir todo ahora
        /* Eduard Version*/

        var cantidad_imagenes_pdf = this.imagenes_pdf;
        if (subidaImagenes) {
          console.log("Terminando desde archivador");
          try {
            //Este objeto va a cambiar segun sea el documento seleccionado se va a mandar a un endpoint
            //Especifico con la estructura
            //El body del endpoint tiene que ser dinamico y tener como keys los label de los tipo documental y como valor el model del input

            let obj = await this.concatenarDatos();
            //obj = obj.trimEnd(',')

            obj = JSON.parse(obj);

            obj.fecha_creacion_int = Date.parse(new Date());
            obj.order = Date.parse(new Date());
            obj.estatus = "HABILITADA";
            obj.extended_properties = "{}";

            Object.entries(obj).forEach(([key, value]) => {
              if (key == "autor")
                obj.autor = this.$store.state.dataUser.dataUser.nombre;
            });

            console.log(obj);
            const busqueda = await this.$store.dispatch("llamado_post", {
              url: `https://docutest.educandote.co/types/collections/${this.nombre_endpoint_almacenamiento}/documents`,
              body: obj,
              tipo_header: "indexador",
            });
            console.log(busqueda);

            const carpeta = await this.$store.dispatch("llamado_post", {
              url: `https://docutest.educandote.co/types/collections/CARPETAS_${this.nombre_endpoint_almacenamiento}/documents`,
              body: {
                nombre_carpeta: "Carpeta 1",
                id_carpeta: "1",
                pertenece_a: busqueda.data.id.toString(),
                estado_carpeta: "ACTIVO",
                fecha_creacion_int: Date.parse(new Date()),
                order: Date.parse(new Date()),
                extended_properties: JSON.stringify({}),
              },
              tipo_header: "indexador",
            });

            console.log(carpeta);

            // let body = "";
            for (let index = 0; index < this.datos_archivos.length; index++) {
              let info_file = JSON.parse(this.datos_archivos[index]);
              console.log("Info file: ", info_file);

              if (info_file.tipo == "pdf") {
                try {
                  let PDFPaginas = await this.contarPaginasPDF(
                    info_file.token,
                    `${info_file.nombre}.pdf`
                  );
                  console.log("Cantidad de paginas: ", PDFPaginas);
                  info_file.imagenes_por_archivo = PDFPaginas;
                } catch (error) {
                  info_file.imagenes_por_archivo = cantidad_imagenes_pdf;
                }
              } else {
                info_file.imagenes_por_archivo = "1";
              }

              // body =
              //   body +
              //   `{"id_busqueda": "${busqueda.data.id.toString()}", "id_carpeta": "${carpeta.data.id.toString()}", "autor": "${
              //     info_file.autor
              //   }", "estado_imagen": "${
              //     info_file.estado_imagen
              //   }", "meta_datos": "", "nombre": "${
              //     info_file.nombre
              //   }", "nombre_sector": "${info_file.nombre_sector}", "sector": "${
              //     info_file.sector
              //   }", "tipo": "${info_file.tipo}", "token": "${
              //     info_file.token
              //   }", "fecha_creacion_int": ${Date.parse(
              //     new Date()
              //   )}, "order": ${Date.parse(
              //     new Date()
              //   )}, "extended_properties": "{}"}\n`;
              /* Eduard Version*/
              let body_pdf = `{"id_busqueda": "${busqueda.data.id.toString()}", "id_carpeta": "${carpeta.data.id.toString()}", "autor": "${
                info_file.autor
              }", "estado_imagen": "${
                info_file.estado
              }", "meta_datos": "", "nombre": "${
                info_file.nombre
              }", "nombre_sector": "${info_file.nombre_sector}", "sector": "${
                info_file.sector
              }", "tipo": "${info_file.tipo}", "token": "${
                info_file.token
              }", "fecha_creacion_int": ${Date.parse(
                new Date()
              )}, "order": ${Date.parse(new Date()) +
                index *
                  250}, "extended_properties": "{}","imagenes_por_archivo": "${
                info_file.imagenes_por_archivo
              }"}\n`;

              await this.set_pdf(body_pdf, this.nombre_endpoint_almacenamiento);

              let body_imagenes = "";
              let name_images = [];
              for (let index = 0; index < cantidad_imagenes_pdf; index++) {
                body_imagenes =
                  body_imagenes +
                  `{"id_busqueda": "${busqueda.data.id.toString()}", "id_carpeta": "${carpeta.data.id.toString()}", "autor": "${
                    info_file.autor
                  }", "estado_imagen": "${"INHABILITADA"}", "meta_datos": "", "nombre": "${index +
                    1}", "nombre_sector": "${
                    info_file.nombre_sector
                  }", "sector": "${
                    info_file.sector
                  }", "tipo": "${"png"}", "token": "${
                    info_file.token
                  }", "fecha_creacion_int": ${Date.parse(
                    new Date()
                  )}, "order": ${Date.parse(new Date()) +
                    index *
                      250}, "extended_properties": "{}","imagenes_por_archivo": "${
                    info_file.imagenes_por_archivo
                  }"}` +
                  "\n";
                name_images.push(index + 1);
              }

              await this.set_images(
                body_imagenes,
                this.nombre_endpoint_almacenamiento
              );
              // const result = await this.get_ids_folder(
              //   info_file.token,
              //   this.nombre_endpoint_almacenamiento
              // );

              // const archivos = await this.$store.dispatch("llamado_post", {
              //   url: `https://docutest.educandote.co/parse/classes/IMAGENESV3_${this.nombre_endpoint_almacenamiento}`,
              //   body: {
              //     id_images: result.id_images,
              //     id_pdf: result.id_pdf,
              //     folder: info_file.token,
              //     method: "PATCH",
              //     url_typesense: `https://docutest.educandote.co/types/collections/IMAGENES_${this.nombre_endpoint_almacenamiento}/documents/`,
              //     date: Date.parse(new Date()),
              //     body_for_enable: {
              //       estado_imagen: "habilitada",
              //     },
              //     body_for_disable: {
              //       estado_imagen: "INHABILITADA",
              //     },
              //     name_images: name_images,
              //     name_pdf: info_file.nombre,
              //   },
              //   tipo_header: "parse",
              // });

              // console.log(archivos);
              /* Eduard Version termina aca*/
            }

            // const imagenes = await this.$store.dispatch("llamado_post", {
            //   url: `https://docutest.educandote.co/types/collections/IMAGENES_${this.nombre_endpoint_almacenamiento}/documents/import?action=create`,
            //   body: body,
            //   tipo_header: "indexador",
            // });

            // console.log(imagenes);

            this.$store.commit(
              "SET_CANTIDAD_DOCUMENTOS_SUBIDOS",
              this.imagenes.length
            );

            this.imagenes = [];
            this.files = [];
            this.indice = 0;

            this.datos_archivos = []; //Variable agregada por fry
            this.contadorImagensSubidas = 0;
            this.reset();
            this.$store.commit("crearToken", 25);
            this.scannerInicio = false;
            this.$store.commit(
              "exitoso",
              "Los archivos han sido escaneados exitosamente"
            );
            this.$store.state.load = false;
          } catch (error) {
            this.$store.commit(
              "error",
              "Ha ocurrido un error inesperado en el momento de almacenar los datos en la coleccion " +
                error.message
            );
            console.log(error);
          }
        }
      } else {
        this.$store.commit(
          "error",
          "Por favor escanea algunas imagenes antes de enviar los datos"
        );
      }
      this.$store.state.dialogCargando = false;
    },
    seleccionTipoDocumental() {
      //'Orden de pago','Impuesto predial','Impuesto ICA','Resoluciones','Historias laborales'
      this.$store.state.formularios.inputs_tipo_documental = {
        campos: [],
      };
      console.log(this.selectDocumento);

      console.log(this.tipos_documentales_completo);
      let aux = this.tipos_documentales_completo.filter(
        (item) =>
          item.nombre
            .toLowerCase()
            .indexOf(this.selectDocumento.toLowerCase()) !== -1
      );

      console.log(aux);
      this.nombre_endpoint_almacenamiento =
        aux[0].nombre_endpoint_almacenamiento;

      for (let index = 0; index < aux[0].parametros.length; index++) {
        aux[0].parametros[index].value = "";
      }
      this.$store.state.formularios.inputs_tipo_documental.campos = [
        ...aux[0].parametros,
      ];
    },
    reset() {
      this.subiendoImagenes = false;
      this.select = [];
      this.now = null;
      this.selectDocumento = "";
      this.nombreDocumento = "";
      this.imagenes = [];
      this.files = [];
      this.$store.state.formularios.inputs_tipo_documental = {
        campos: [],
      };
    },
  },
  computed: {
    ...mapState("dataUser", ["dataUser"]),
  },
};
</script>
